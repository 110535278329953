#side-bar
{
	@include breakpoint(4)
	{
		position: fixed;
		top: 0;
		right: $main_width;
		bottom: 0;
		left: 0;
	}
}

nav
{
	ul
	{
		width: 100%;

		li
		{
			a
			{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				height: 100%;
				padding: $rule;
				text-align: center;

				.logo
				{
					height: 50px;
					width: 210px;
					border: none !important;
					text-indent: -9999px;
					background-image: url(img/logo.svg);
					background-repeat: no-repeat;
					background-position: center;
				}

				.title
				{
					text-transform: uppercase;
					font-size: 2rem;
					letter-spacing: 0.1rem;
				}

				.subtitle
				{
					display: block;
					margin-top: $rule;
					padding: $rule/2 0;
					text-transform: uppercase;
					letter-spacing: 0.2rem;
					@include title-font;
					font-size: 1.3rem;
					font-weight: 300;
				}
			}

			&:nth-child(3n+1)
			{
				background-color: $nav_color1;

				a
				{
					color: $secondary_color;

					.title
					{
						border-bottom: 1px solid $nav_color3;
					}
				}
			}

			&:nth-child(2n+2)
			{
				background-color: $nav_color2;

				a
				{
					color: $secondary_color;

					.title
					{
						border-bottom: 1px solid $nav_color3;
					}
				}
			}

			&:nth-child(3n)
			{
				background-color: $nav_color3;

				a
				{
					color: $nav_color1;

					.title
					{
						border-bottom: 1px solid $nav_color1;
					}
				}
			}
		}
	}

	@include breakpoint(1)
	{
		ul
		{
			display: flex;
			flex-grow: 1;
			flex-direction: row;

			li
			{
				width: 33.33%;

				a
				{
					padding: $rule*2 $rule/2;
				}

				p
				{
					width: 90%;
					padding: $rule/2 $rule;
					text-align: center;
				}
			}
		}
	}

	@include breakpoint(4)
	{
		ul
		{
			flex-direction: column;
			height: 100vh;

			li
			{
				position: relative;
				flex-grow: 1;
				width: auto;

				a
				{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					width: 100%;
					height: 100%;
					padding: $rule;
					text-align: center;
				}
			}
		}

	}
}
