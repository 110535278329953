#news-slider
{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	padding: $rule*2 $rule;
	color: $primary_color;
	background-color: $secondary_color;

	.thumb
	{
		width: 200px;
		height: 150px;
		margin-bottom: $rule;
	}
	
	h2
	{
		margin-bottom: $rule;
		border-top: 1px solid $primary_color;
		border-bottom: 1px solid $primary_color;
		font-family: Droid serif, sans-serif;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-size: 2rem;
	}

	p
	{
		width: 70%;
		text-transform: uppercase;
		font-family: Oswald, sans-serif;
		font-size: 1.3rem;
		font-weight: 100;
		letter-spacing: 0.1rem;
		text-align: center;
	}
	
	@include breakpoint(1)
	{

		display: flex;
		flex-grow: 1;
		width: 50%;
	}
	
	@include breakpoint(2)
	{
		width: 33.33%;
		padding: 0 $rule;
	}
	
	>div
	{
		&.hide
		{
			display: none;
		}
		
		&.show
		{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			animation: slidein 1s $ease-in-out-circ;
		}
	}

	ul
	{
		padding-top: $rule;

		li
		{
			float: left;
			width: 10px;
			height: 10px;
			margin: 0 $rule/6;
			background-color: rgba(255,255,255,0.4);

			&.active
			{
				background-color: #e5d9ce;
			}
		}
	}
}