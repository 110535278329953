/* Magnific Popup CSS */

@import 'magnific-popup-settings';

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////

////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg
{
	position: fixed;
	z-index: $mfp-z-index-base + 2;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background: $mfp-overlay-color;
	opacity: $mfp-overlay-opacity;

	@if $mfp-IE7support
	{
		filter: unquote('alpha(opacity=#{$mfp-overlay-opacity*100})');
	}
}

// Wrapper for popup
.mfp-wrap
{
	position: fixed;
	z-index: $mfp-z-index-base + 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none !important;
	backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 $mfp-popup-padding-left;
	text-align: center;
}

// Vertical centerer helper
.mfp-container
{
	&:before
	{
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top
{
	.mfp-container
	{
		&:before
		{
			display: none;
		}
	}
}

// Popup content holder
.mfp-content
{
	position: relative;
	z-index: $mfp-z-index-base + 5;
	display: inline-block;
	margin: 0 auto;
	text-align: left;
	vertical-align: middle;

}
.mfp-inline-holder,
.mfp-ajax-holder
{
	.mfp-content
	{
		width: 100%;
		cursor: auto;
	}
}

// Cursors
.mfp-ajax-cur
{
	cursor: progress;
}

.mfp-zoom-out-cur
{
	&, .mfp-image-holder .mfp-close
	{
		cursor: zoom-out;
	}
}

.mfp-zoom
{
	cursor: zoom-in;
}
.mfp-auto-cursor
{
	.mfp-content
	{
		cursor: auto;
	}
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter
{
	user-select: none;
}

// Hide the image during the loading
.mfp-loading
{
	&.mfp-figure
	{
		display: none;
	}
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden
{
	// From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
	.mfp-hide
	{
		position: absolute !important;
		overflow: hidden !important;
		width: 1px !important;
		height: 1px !important;
		margin: -1px !important;
		padding: 0 !important;
		border: 0 !important;
		clip: rect(0 0 0 0) !important;
	}
}
@else
{
	.mfp-hide
	{
		display: none !important;
	}
}

////////////////////////
// 2. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader
{

	position: absolute;
	z-index: $mfp-z-index-base + 4;
	top: 50%;
	right: 8px;
	left: 8px;
	width: auto;
	margin-top: -0.8em;
	text-align: center;
	color: $mfp-controls-text-color;

	a
	{
		color: $mfp-controls-text-color;

		&:hover
		{
			color: $mfp-controls-text-color-hover;
		}
	}
}

// Hide preloader when content successfully loaded
.mfp-s-ready
{
	.mfp-preloader
	{
		display: none;
	}
}

// Hide content when it was not loaded
.mfp-s-error
{
	.mfp-content
	{
		display: none;
	}
}

// CSS-reset for buttons
button
{
	&.mfp-close,
	&.mfp-arrow
	{
		z-index: $mfp-z-index-base + 6;
		overflow: visible;
		display: block;
		padding: 0;
		border: 0;
		background: transparent;
		cursor: pointer;
		outline: none;
		appearance: none;
		box-shadow: none;
	}

	&::-moz-focus-inner
	{
		border: 0;
		padding: 0;
	}
}

// Close icon
.mfp-close
{
	@if $mfp-IE7support
	{
		filter: unquote('alpha(opacity=#{$mfp-controls-opacity*100})');
	}

	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	padding: 0 0 18px 10px;
	line-height: $rule;
	text-decoration: none;
	text-align: center;
	font-style: normal;
	font-size: 1.5rem;
	font-family: Arial, Baskerville, monospace;
	color: $mfp-controls-color;
	opacity: $mfp-controls-opacity;

	&:hover,
	&:focus
	{
		opacity: 1;
		@if $mfp-IE7support
		{
			filter: unquote('alpha(opacity=#{1*100})');
		}
	}

	&:active
	{
		top: 1px;
	}
}

.mfp-close-btn-in
{
	.mfp-close
	{
		color: $mfp-inner-close-icon-color;
	}
}
.mfp-image-holder,
.mfp-iframe-holder
{
	.mfp-close
	{
		right: -6px;
		width: 100%;
		padding-right: 6px;
		text-align: right;
		color: $mfp-controls-color;
	}
}

// "1 of X" counter
.mfp-counter
{
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1rem;
	line-height: 1.8;
	white-space: nowrap;
	color: $mfp-controls-text-color;
}

// Navigation arrows
@if $mfp-include-arrows
{
	.mfp-arrow
	{
		position: absolute;
		top: 50%;
		width: 90px;
		height: 110px;
		margin: 0;
		margin-top: -55px;
		padding: 0;
		opacity: $mfp-controls-opacity;

		@if $mfp-IE7support
		{
			filter: unquote('alpha(opacity=#{$mfp-controls-opacity*100})');
		}

		tap-highlight-color: rgba(0,0,0,0);

		&:active
		{
			margin-top: -54px;
		}

		&:hover,
		&:focus
		{
			opacity: 1;

			@if $mfp-IE7support
			{
				filter: unquote('alpha(opacity=#{1*100})');
			}
		}

		&:before,
		&:after,
		.mfp-b,
		.mfp-a
		{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 0;
			height: 0;
			margin-top: 35px;
			margin-left: 35px;
			border: medium inset transparent;
		}

		&:after,
		.mfp-a
		{
			top: 8px;
			border-top-width: 13px;
			border-bottom-width: 13px;
		}

		&:before,
		.mfp-b
		{
			border-top-width: 21px;
			border-bottom-width: 21px;
			opacity: 0.7;
		}
	}

	.mfp-arrow-left
	{
		left: 0;

		&:after,
		.mfp-a
		{
			margin-left: 31px;
			border-right: 17px solid $mfp-controls-color;
		}

		&:before,
		.mfp-b
		{
			margin-left: 25px;
			border-right: 27px solid $mfp-controls-border-color;
		}
	}

	.mfp-arrow-right
	{
		right: 0;

		&:after,
		.mfp-a
		{
			margin-left: 39px;
			border-left: 17px solid $mfp-controls-color;
		}

		&:before,
		.mfp-b
		{
			border-left: 27px solid $mfp-controls-border-color;
		}
	}
}

// Iframe content type
@if $mfp-include-iframe-type
{
	.mfp-iframe-holder
	{
		padding-top: $mfp-iframe-padding-top;
		padding-bottom: $mfp-iframe-padding-top;

		.mfp-content
		{
			width: 100%;
			max-width: $mfp-iframe-max-width;
			line-height: 0;
		}

		.mfp-close
		{
			top: -40px;
		}
	}

	.mfp-iframe-scaler
	{
		overflow: hidden;
		width: 100%;
		height: 0;
		padding-top: $mfp-iframe-ratio * 100%;

		iframe
		{
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			box-shadow: $mfp-shadow;
			background: $mfp-iframe-background;
		}
	}
}

// Image content type
@if $mfp-include-image-type
{
	/* Main image in popup */
	img
	{
		&.mfp-img
		{
			box-sizing: border-box;
			display: block;
			width: auto;
			height: auto;
			max-width: 100%;
			padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
			margin: 0 auto;
			line-height: 0;
		}
	}

	/* The shadow behind the image */
	.mfp-figure
	{
		line-height: 0;

		&:after
		{
			content: '';
			position: absolute;
			z-index: -1;
			top: $mfp-image-padding-top;
			right: 0;
			bottom: $mfp-image-padding-bottom;
			left: 0;
			display: block;
			width: auto;
			height: auto;
			box-shadow: $mfp-shadow;
			background: $mfp-image-background;
		}

		small
		{
			display: block;
			font-size: 1rem;
			line-height: 1.6;
			color: $mfp-caption-subtitle-color;
		}

		figure
		{
			margin: 0;
		}
	}

	.mfp-bottom-bar
	{
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		margin-top: -$mfp-image-padding-bottom + 4;
		cursor: auto;
	}

	.mfp-title
	{
		padding-right: 36px; // leave some space for counter at right side
		text-align: left;
		line-height: 1.3rem;
		word-wrap: break-word;
		color: $mfp-caption-title-color;
	}

	.mfp-image-holder
	{
		.mfp-content
		{
			max-width: 100%;
		}
	}

	.mfp-gallery
	{
		.mfp-image-holder
		{
			.mfp-figure
			{
				cursor: pointer;
			}
		}
	}

	@if $mfp-include-mobile-layout-for-image
	{
		@media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px)
		{
			/**
			   * Remove all paddings around the image on small screen
			   */
			.mfp-img-mobile
			{
				.mfp-image-holder
				{
					padding-left: 0;
					padding-right: 0;
				}

				img
				{
					&.mfp-img
					{
						padding: 0;
					}
				}

				.mfp-figure
				{
					// The shadow behind the image
					&:after
					{
						top: 0;
						bottom: 0;
					}

					small
					{
						display: inline;
						margin-left: 5px;
					}
				}

				.mfp-bottom-bar
				{
					position: fixed;
					top: auto;
					bottom: 0;
					box-sizing: border-box;
					margin: 0;
					padding: 3px 5px;
					background: rgba(0,0,0,0.6);

					&:empty
					{
						padding: 0;
					}
				}

				.mfp-counter
				{
					top: 3px;
					right: 5px;

				}

				.mfp-close
				{
					position: fixed;
					top: 0;
					right: 0;
					width: 35px;
					height: 35px;
					padding: 0;
					line-height: 2;
					text-align: center;
					background: rgba(0, 0, 0, 0.6);
				}
			}
		}
	}
}

// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px)
{
	.mfp-arrow
	{
		transform: scale(0.75);
	}

	.mfp-arrow-left
	{
		transform-origin: 0;
	}

	.mfp-arrow-right
	{
		transform-origin: 100%;
	}

	.mfp-container
	{
		padding-left: $mfp-popup-padding-left-mobile;
		padding-right: $mfp-popup-padding-left-mobile;
	}
}

// IE7 support
// Styles that make popup look nicier in old IE
@if $mfp-IE7support
{
	.mfp-ie7
	{
		.mfp-img
		{
			padding: 0;
		}

		.mfp-bottom-bar
		{
			left: 50%;
			width: 600px;
			margin-left: -300px;
			margin-top: 5px;
			padding-bottom: 5px;
		}

		.mfp-container
		{
			padding: 0;
		}

		.mfp-content
		{
			padding-top: 44px;
		}

		.mfp-close
		{
			top: 0;
			right: 0;
			padding-top: 0;
		}
	}
}