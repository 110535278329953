#image-wrapper
{
	width: 100%;
	height: 350px;
	padding: $rule*2 0;
	text-align: center;
	background-size: cover;
	color: $primary_color;

	h2
	{
		position: relative;
		padding: $rule/2 0;
		margin: 0 auto;
		font-size: 3rem;

		&:after
		{
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			width: 20%;
			margin: 0 auto;
			border-bottom: 1px solid $primary_color;
		}

		@include breakpoint(1)
		{
			width: 50%;
			font-size: 6rem;
		}
	}

	p
	{
		margin: 0 auto;
		padding: $rule/2 $rule;
		text-transform: uppercase;
		font-size: 1.5rem;
		letter-spacing: 0.2rem;
		font-weight: 300;
		@include title-font;

		@include breakpoint(1)
		{
			width: 30%;
		}
		@include breakpoint(2)
		{
			width: 30%;
		}
	}
}

#product-description-wrapper
{
	overflow: hidden;
	padding: $rule 0;
	background-color: $secondary_color;

	p
	{
		line-height: 3;
		font-weight: 100;
		font-size: 1.2rem;
		color: $primary_color;
	}

}

#product-body-wrapper
{
	padding: $rule 0;
	background-color: $color01;

	h3
	{
		width: auto;
		margin-bottom: $rule;
		padding: $rule/4 0;
		font-size: 2rem;
	}

	p
	{
		clear: both;
		line-height: 1.5;
		font-size: 1.3rem;
		color: $primary_color;
	}
}

.product
{
	width: 100%;
	color: $primary_color;

	@for $i from 1 through 20
	{
		&:nth-child(#{$i})
		{
			animation: slidein 1s;
			animation-fill-mode: both;
			animation-delay: 500ms + ( $i * 200ms );
		}
	}

	a
	{
		display: block;
		height: 100%;
	}

	img
	{
		width: 100%;

		@include breakpoint(2)
		{
			position: relative;
			display: block;
		}
	}

	h2
	{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: $rule $rule;

		&:after
		{
			content: '';
			position: absolute;
			top: -25px;
			right: 0;
			left: 0;
			width: 0;
			height: 0;
			margin: 0 auto;
		}

		span
		{
			position: relative;
			padding-bottom: $rule/2;
			padding-left: $rule;
			padding-right: $rule;
			font-size: 2.2rem;
			text-transform: uppercase;
			text-align: center;
			letter-spacing: 0.2rem;

			&:after
			{
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				width: 60%;
				margin: 0 auto;
				border-bottom: 1px solid $primary_color;
			}

			@include breakpoint(2)
			{
				padding-left: 0;
				padding-right: 0;
				font-size: 2rem;
			}
		}
	}
}

.product
{
	@include breakpoint(1)
	{
		display: flex;
		width: 50%;
	}

	@include breakpoint(3)
	{
		width: 33.33%;
	}

	&:nth-child(2n)
	{
		background-color: $third_color;

		h2
		{
			&:after
			{
				border-left: solid 25px transparent;
				border-right: solid 25px transparent;
				border-bottom: solid 30px $third_color;
			}
		}
	}

	&:nth-child(2n+1)
	{
		background-color: $secondary_color;

		h2
		{
			&:after
			{
				border-left: solid 25px transparent;
				border-right: solid 25px transparent;
				border-bottom: solid 30px $secondary_color;
			}
		}
	}
}