$primary_color: #ffffff;
$secondary_color: #2b261b;
$third_color: #5b4b2f;
$nav_color1: #ffffff;
$nav_color2: #e5d9ce;
$nav_color3: #bfa184;

$color01: #3b3b3b;
$color02: #333333;

$rule: 2rem;
$main_width: 80%;
$sidebar_width: 20%;
$product_width: 33.33%;

$ease-in-out-circ: cubic-bezier( 0.79, 0.14, 0.15, 0.86 );

$breakpoints: (
		650px,
		750px,
		1024px,
		1150px,
		1280px,
		1375px,
		1500px,
		1600px,
		1750px,
		1900px,
);

.wrap
{
	width: 80%;
	margin: 0 auto;
	@include breakpoint(1)
	{
		width: 70%;

	}
	@include breakpoint(2)
	{
		width: 60%;
	}
}

//SCROLLBAR//

::-webkit-scrollbar
{
	width: 10px;
}

::-webkit-scrollbar-thumb
{
	background: $third_color;
}

::selection
{
	background: $primary_color;
	color: $secondary_color;
}
