footer
{
	overflow: hidden;
	width: 100%;
	padding: $rule/2;
	color: $primary_color;
	background-color: $color01;

	p
	{
		float: left;
		width: 50%;
		padding: $rule/2;
		@include title-font;
		font-weight: 300;
	}

	h2
	{
		float: right;
		padding: $rule/2;
		text-indent: -9000px;
		background-image: url('img/tnt.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50px 50px;
	}
}