main
{
	@include breakpoint(1)
	{
		position: absolute;
	}

	@include breakpoint(4)
	{
		top: 0;
		right: 0;
		bottom: 0;
		left: $sidebar_width;
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0;
	}
}

#product-wrapper
{
	display: flex;
	flex-wrap: wrap;
}
