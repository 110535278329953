#map
{
	width: 100%;
	min-height: 300px;

	@include breakpoint(1)
	{
		min-height: 550px;
	}
}

#contact-description
{
	padding: $rule;

	@include breakpoint(1)
	{
		float: left;
		width: 50%;
	}
}
#contact-wrapper
{
	padding: $rule;

	@include breakpoint(1)
	{
		float: left;
		width: 50%;
	}
}

.contactform
{
	overflow: hidden;
	color: $primary_color;

	fieldset
	{
		margin-bottom: $rule/2;

		&.has_error
		{
			color: $nav_color2;
		}

		&.required label:after
		{
			content: '*';
			color: $nav_color2;
		}
	}

	label
	{
		display: block;
		padding: $rule/2 0;
		@include title-font;
		text-transform: uppercase;
	}

	textarea,
	input
	{
		width: 100%;
		padding: 10px;
		border: none;
		font-size: 1.1rem;
		outline: none;
		color: $color02;
		background-color: $nav_color2;

		&:focus
		{
			color: $color02;
		}

		@include breakpoint(2)
		{
			width: 70%;
		}
	}

	textarea
	{
		width: 100%;
		height: 200px;
		resize: vertical;

		@include breakpoint(2)
		{
			width: 70%;
		}
	}

	input
	{
		height: $rule*1.3;
	}

	button
	{
		display: block;
		padding: $rule/2 $rule;
		border: 0;
		@include title-font;
		text-transform: uppercase;
		font-size: 1.2rem;
		color: $secondary_color;
		background-color: $nav_color2;
		cursor: pointer;
	}

	.error
	{
		color: red;
	}
}