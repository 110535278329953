#carousel
{
	overflow: hidden;
	width: 100%;
	background-color: $third_color;

	ul
	{
		display: none;

		li
		{
			float: left;
			width: 100%;

			@include breakpoint(1)
			{
				width: 50%;
			}
			@include breakpoint(3)
			{
				width: 33.33%;
			}
			@include breakpoint(5)
			{
				width: 25%;
			}
		}

		&.loaded
		{
			overflow: hidden;
			display: block;
			transition: transform 1s;
		}
	}
}

.carousel-next-button,
.carousel-prev-button
{
	position: absolute;
	top: 50%;
	display: block;
	width: $rule * 1.5;
	height: $rule * 1.5;
	margin-top: -( $rule * 1.5 )/2;
	background-color: $secondary_color;
	cursor: pointer;

	&.hide
	{
		display: none;
	}
}
.carousel-prev-button
{
	background-color: $secondary_color;
	background-image: url(img/arrow_down.png);
	background-position: center;
	background-repeat: no-repeat;
	transform: rotate(90deg);
}
.carousel-next-button
{
	background-color: $secondary_color;
	background-image: url(img/arrow_down.png);
	background-position: center;
	background-repeat: no-repeat;
	transform: rotate(270deg);
}

.carousel-next-button
{
	right: 0;
}

.carousel-prev-button
{
	left: 0;
}