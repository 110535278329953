html
{
	font-size: 75%; //12px
}

body
{
	@include default-font;
	font-size: 1rem;
	color: $primary_color;
	background-color: $color01;
}

#page-wrapper
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.wysywig
{
	ul
	{
		margin: $rule 0;
		column-count: 2;

		li
		{
			padding: $rule /2 0;
			margin-left: $rule/2;
			font-size: 1.3rem;
			@include title-font;
			font-weight: lighter;
			list-style-type: disc;
		}
	}

	strong
	{
		font-weight: bold;
	}

	em
	{
		font-style: italic;
	}

}