header
{
	position: relative;
	height: $rule*3;
	width: 100%;
	text-align: center;
	color: $primary_color;
	background-color: $secondary_color;

	.crumb
	{
		position: absolute;
		top: $rule;
		left: $rule;
		font-size: 1.4rem;
		text-indent: -9999px;

		&:before
		{
			content: '';
			display: block;
			float: left;
			width: 20px;
			height: 20px;
			background-image: url('img/arrow_left.png');
			background-repeat: no-repeat;
			background-position: center center;
		}

		@include breakpoint(1)
		{
			text-indent: 0;
		}
	}

	h2
	{
		padding: $rule 0;
		text-transform: uppercase;
		font-size: 1.5rem;
	}
}

body.fixed-header
{
	header
	{
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
	}
}