#content-wrapper
{
	img
	{
		display: block;
		width: 100%;
	}

	@include breakpoint(1)
	{
		position: absolute;
		right: 0;
		left: 0;
		height: 100%;
	}

	@include breakpoint(4)
	{
		top: 0;
		right: 0;
		bottom: 0;
		left: $sidebar_width;
		overflow-y: auto;
	}
}

body.fixed-header
{
	#content-wrapper
	{
		padding-top: $rule*3;
	}
}

