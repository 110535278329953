@include keyframes( fadein )
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@include keyframes( slidein )
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}