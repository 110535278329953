.cookie-notice
{
	$position: 'bottom';
	$style: 'compact';
	$padding: $rule $rule $rule / 1.5 $rule;
	$font-size: 1.1rem;

	$opacity: 1;
	$background-color: $nav_color3;
	$text-color: $nav_color1;
	$text-align: center;

	$button-background-color: $secondary_color;
	$button-text-color: $nav_color1;
	$button-border-radius: 0;

	$link-text-color: $nav_color1;

	position: fixed;
	width: 100%;
	z-index: 10000;

	@media screen and (min-width: 650px)
	{
		width: inherit;
	}

	@if ( $style == 'compact')
	{
		top: if($position == 'top', $rule / 3, initial );
		bottom: if($position == 'bottom', 0, initial );
		right: 0;
	}
	@else
	{
		top: if($position == 'top', 0, initial );
		bottom: if($position == 'bottom', 0, initial );
		right: 0;
		left: 0;
	}

	padding: $padding;
	opacity: $opacity;
	font-size: $font-size;
	background-color: rgba( $background-color, $opacity );
	color: $text-color;

	.cookie-notice-content
	{
		display: flex;
		justify-content: space-between;
		flex-direction: if($style == 'compact', column, inherit);
		align-items: center;
	}

	.cookie-notice-text
	{
		padding: if($style == 'compact', 0 0 $rule / 2 0, 0 $rule 0);
		text-align: $text-align;

		a
		{
			border-bottom: 1px solid $link-text-color;
			color: $link-text-color;
		}
	}

	#cookie-notice-agree-btn
	{
		display: block;
		padding: $rule / 2 $rule;
		border: 0;
		@include title-font;
		text-transform: uppercase;
		font-size: 1rem;
		color: $button-text-color;
		background-color: $button-background-color;
		cursor: pointer;
	}
}
